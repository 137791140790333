<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img fluid :src="logoUrl" alt="Login" class="login_logo_img" />
        <h2 class="brand-text text-primary ml-1">
          {{ $t("Portal") }}
        </h2>
      </b-link>

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Vector" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("Welcome to Portal") }}! 👋
            <div style="width: 50%;">
              <locale style="list-style: none;" />
            </div>
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Please sign-in to your account") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group
                :label="$t('Email or Username')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t("Sign in") }}
              </b-button>

              <span class=" regspan">
                Register a new account or try the app</span
              >
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import axios from "axios";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/forPortal-light.png"),
      logoImg: require("@/assets/images/pages/login_custom.png"),

      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  beforeCreate() {
    localStorage.setItem("token", "");
    localStorage.removeItem("username");
    localStorage.removeItem("pagesize");
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/forPortal-Dark.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.logoImg = require("@/assets/images/pages/login_custom.png");
        return this.logoImg;
      }
      return this.logoImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          var logindata = JSON.stringify({
            login: this.userEmail,
            pass: this.password,
            app: "bcportal",
          });

          var config = {
            method: "post",
            url:
              "https://engine.netsupport.dk:7080/account/v1/retrievedebtorbctoken",
            headers: {
              "Content-Type": "application/json",
            },
            data: logindata,
          };
          axios(config)
            .then((response) => {
              console.log("login", response);
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("username", this.userEmail);
              localStorage.setItem("debtorname", response.data.debtorName);
              this.$router.push({ name: "home" });
              //window.location.href = '/';
            })
            .catch(function(error) {
              console.log("error", error);
              alert("User Not Found");
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style scoped>
.login_logo_img {
  width: 100%;
  max-width: 55px;
}
.brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.regspan {
  margin-top: 12px px;
  display: block;
  font-weight: bolder;
  font-size: 16px;
}
</style>
